import AlternatingList from './alternatingList'
import AnimatedInfographic from './animatedInfographic'
import Articles from './articles'
import BasicInfographic from './basicInfographic'
import BlogGrid from './blogGrid'
import BlogHeader from './blogHeader'
import BlogImageCarousel from './blogImageCarousel/blogImageCarousel'
import BlogImageLeftParagraphsRight from './blogImageLeftParagraphsRight/blogImageLeftParagraphsRight'
import BlogParagraphs from './blogParagraphs'
import BlogSliderWrapper from './blogSlider'
import BlogVideo from './blogVideo'
import CareerCarousel from './careerCarousel/careerCarouselWrapper'
import ContactForm from './contactForm'
import CTA from './cta'
import devDescription from './devDescription'
import devDocumentation from './devDocumentation'
import Hero from './hero'
import IconGrid from './iconGrid'
import IconsTextSliderWrapper from './iconsTextSlider'
import OneColumnInfo from './oneColumnInfo'
import Overview from './overview'
import Process from './process'
import TeamMembers from './teamMembers'
import TwoColumnInfo from './twoColumnInfo'

export default {
  'alternating-ordered-list': AlternatingList,
  'animated-infographic': AnimatedInfographic,
  articles: Articles,
  'basic-infographic': BasicInfographic,
  'blog-grid': BlogGrid,
  'blog-header': BlogHeader,
  'blog-image-carousel': BlogImageCarousel,
  'blog-image-left-paragraphs-right': BlogImageLeftParagraphsRight,
  'blog-paragraphs': BlogParagraphs,
  'blog-slider': BlogSliderWrapper,
  'blog-video': BlogVideo,
  'career-slider': CareerCarousel,
  'contact-form': ContactForm,
  cta: CTA,
  hero: Hero,
  'icon-grid': IconGrid,
  'icons-text-slider': IconsTextSliderWrapper,
  'main-dev-description': devDescription,
  'main-developer-documentation': devDocumentation,
  'one-column-infographic': OneColumnInfo,
  overview: Overview,
  'reit-process': Process,
  'team-members': TeamMembers,
  'two-column-infographic': TwoColumnInfo
}
