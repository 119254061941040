import React, { useState } from 'react'

import * as styles from './articles.module.scss'

export default ({ component: { article } }) => {
  const [index, setIndex] = useState(0)

  const handlePrevious = () => {
    const lastIndex = article.length - 1
    const shouldResetIndex = index === 0
    setIndex(shouldResetIndex ? lastIndex : index - 1)
  }

  const handleNext = () => {
    const lastIndex = article.length - 1
    const shouldResetIndex = index === lastIndex
    setIndex(shouldResetIndex ? 0 : index + 1)
  }

  return (
    <section className={styles.section}>
      <div className={styles.articleContainer}>
        {article.map((article, i) => (
          <div
            key={i}
            className={styles.article}
            style={{ transform: `translateX(-${index * 100}%)` }}
          >
            <div
              className={styles.icon}
              style={{ backgroundImage: `url(${article.icon})` }}
            />
            <h3 className={styles.label}>{article.label}</h3>
            <p className={styles.content}>{article.content}</p>
          </div>
        ))}
      </div>

      <div className={styles.controls}>
        <button
          className={styles.leftArrow}
          onClick={handlePrevious}
        />
        <button
          className={styles.rightArrow}
          onClick={handleNext}
        />
      </div>
      <div className={styles.articleContainerMobile}>
        {article.map((article, i) => (
          <div
            key={i}
            className={styles.articleMobile}
            style={{ transform: `translateX(-${index * 135}%)` }}
          >
            <div
              className={styles.iconMobile}
              style={{ backgroundImage: `url(${article.icon})` }}
            />
            <h3 className={styles.labelMobile}>{article.label}</h3>
            <p className={styles.contentMobile}>{article.content}</p>
          </div>
        ))}
      </div>

      <div className={styles.controlsMobile}>
        <button
          className={styles.leftArrowMobile}
          onClick={handlePrevious}
        />
        <button
          className={styles.rightArrowMobile}
          onClick={handleNext}
        />
      </div>
    </section>
  )
}
