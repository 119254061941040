import React from 'react'
import { Link } from 'gatsby'

type props = {
  children?: React.ReactNode | [React.ReactNode]
  className?: string
  activeClassName?: string
  onClick?: any
  page: string
}

export default ({ children = [], className, activeClassName, page, onClick = () => {} }: props) => page
  ? (
    <Link to={page.replace('src/content/pages', '').replace('.json', '').replace('index', '')} className={className} activeClassName={activeClassName} onClick={onClick}>
      {children}
    </Link>
    )
  : null
