import React, { useEffect, useState } from 'react'
import { headerComponents } from '../../../settings/settings.json'
import Fade from 'react-reveal/Fade'

import Components from '../header/components'

import * as styles from './header.module.scss'

export default () => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const eventListener = () => {
      setScrolled(window.pageYOffset > 0)
    }
    eventListener()
    window.addEventListener('scroll', eventListener)
  }, [])
  return (
    <header className={styles.header + `${scrolled ? ` ${styles.scrolled}` : ''}`}>
      {headerComponents.map((component, i) => {
        const index = Object.keys(Components).indexOf(component.template)
        const Component = index !== null && Object.values(Components)[index]
        return Component
          ? (
            <Component key={i} component={component} scrolled={scrolled} />
            )
          : (
            <Fade left>
              <p key={i} className={styles.title} style={{ marginBottom: 0 }}>The <strong>{component.template}</strong> component does not exist</p>
            </Fade>
            )
      })}
    </header>
  )
}
