import React from 'react'
import { navigate } from 'gatsby'

import * as styles from './blogHeader.module.scss'

export default ({ component: { image, title } }) => (
  <div className={styles.section}>
    <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
    <div className={styles.info}>
      <div className={styles.backContainer} onClick={() => navigate(-1)}>
        <div className={styles.backArrow} />
        <p className={styles.backLabel}>Back</p>
      </div>
      <h1 className={styles.title}>{title}</h1>
    </div>
  </div>
)
