// extracted by mini-css-extract-plugin
export var section = "contactForm-module--section--2n5oE";
export var text = "contactForm-module--text--1x5yl";
export var line = "contactForm-module--line--2-4VC";
export var formContainer = "contactForm-module--form-container--3EeRR";
export var heading = "contactForm-module--heading--2Vc0X";
export var form = "contactForm-module--form--363EC";
export var error = "contactForm-module--error--3gagU";
export var row = "contactForm-module--row--33XFQ";
export var alt = "contactForm-module--alt--12sW2";
export var field = "contactForm-module--field--3bIjR";
export var label = "contactForm-module--label--jEndW";
export var input = "contactForm-module--input--2iNSi";
export var imageSelect = "contactForm-module--image-select--inmw2";
export var via = "contactForm-module--via--1C6ed";
export var email = "contactForm-module--email--34kbe";
export var phone = "contactForm-module--phone--3Rio4";
export var buttonContainer = "contactForm-module--button-container--2WSnB";
export var button = "contactForm-module--button--2w-gm";
export var dotLine = "contactForm-module--dot-line--2CnAO";
export var dot = "contactForm-module--dot--336a-";