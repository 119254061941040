import React from 'react'

import * as styles from './oneColumnInfo.module.scss'

export default ({ component: { icons, headingTwoColumn} }) => {
  return (
    <section className={styles.section}>
      <div className={styles.column}>
        <h2 className={styles.label}>{headingTwoColumn.label}</h2>
        <p className={styles.content}>{headingTwoColumn.content}</p>
        <div className={styles.icons}>
          {icons.map((item, i) => {
            return (
              <div key={i} className={styles.item}>
                <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />
                <p className={styles.iconText}>{item.iconText}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
