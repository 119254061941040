import React from 'react'
import Components from './components'
import Fade from 'react-reveal/Fade'

import * as styles from './main.module.scss'

export default ({ components, children, templates }) => {
  return (
    <main className={styles.main}>
      {components && components.length
        ? components.map((component, i) => {
            const index = Object.keys(Components).indexOf(component.template)
            const Component = index !== null && Object.values(Components)[index]

            return Component
              ? (
                  component.template !== 'alternating-ordered-list'
                    ? (
                      <Fade up key={i}>
                        <Component key={i} component={component} templates={templates} />
                      </Fade>)
                    : <Component key={i} component={component} templates={templates} />
                )
              : (
                <Fade up key={i}>
                  <p key={i} className={styles.title}>The <strong>{component.template}</strong> component does not exist</p>
                </Fade>
                )
          })
        : null}
      {children}
    </main>
  )
}
