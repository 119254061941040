import React from 'react'
import Logo from '../logo'
import SharedTextButton from '../../../../../utilities/sharedTextButton/sharedTextButton'

import * as styles from './cta.module.scss'

export default ({ component: { image, imageX, imageY, label, sharedTextButton } }) => {
  return (
    <section className={styles.section}>
      <div className={styles.logoContainer}>
        <Logo {...{ image, xPos: imageX ?? '5', yPos: imageY ?? '-20' }} />
      </div>
      <div className={styles.contentContainer}>
        <h3 className={styles.content}>{label}</h3>
        <SharedTextButton {...sharedTextButton} color='#414042' arrow='/arrow-dark.svg' />
      </div>
    </section>
  )
}
