import React from 'react'
import parse from 'html-react-parser'

import * as styles from './devDocumentation.module.scss'

export default ({ component: { sections } }) => (
  <>
    {sections.map((section, i) => (
      <section className={styles.section} key={i} id={section.id}>
        {section.content && parse(section.content)}
      </section>
    ))}
  </>
)
