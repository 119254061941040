import React from 'react'
import parse from 'html-react-parser'

import * as styles from './blogImageLeftParagraphsRight.module.scss'

export default (props) => {
  const { component: { imageSmall, paragraphs } } = props
  return (
    <section className={styles.section}>
      <div className={styles.image} style={{ backgroundImage: `url(${imageSmall})` }} />
      <div className={styles.text}>
        {paragraphs && parse(paragraphs)}
      </div>
    </section>
  )
}
