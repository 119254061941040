import React from 'react'
import * as styles from './portal.module.scss'

export default ({ component }) => {
  return (
    <div className={styles.container}>
      <a className={styles.icon} href='https://e78partnersre.investorflow.com/' target='_blank' rel='noopener noreferrer' />
    </div>
  )
}
