import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import * as styles from './animatedInfographic.module.scss'
import Stats from '../stats'
import BackgroundImageWrapper from '../backgroundImageWrapper'

export default ({ component }) => {
  const { ref, inView } = useInView({
    threshold: 0
  })
  const [countUp, setCountUp] = useState(inView)

  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUp(inView)
    }
  }

  const {
    animatedGraphic: { content, statistic },
    headingAnimated,
    statistics
  } = component
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <BackgroundImageWrapper image={component.image}>
          <div className={styles.labelContainer}>
            <h2 className={styles.label}>{headingAnimated.label}</h2>
            <p className={styles.content}>{headingAnimated.content}</p>
          </div>
          <div className={styles.animatedGraphic}>
            <div ref={ref} className={styles.graphic + `${inView ? ` ${styles.active}` : ''}`} />
            <div className={styles.arrow}>
              <svg viewBox="0 0 50 50">
                <path d="M 0 0 L 48 22 C 50 23 50 27 48 28 L 0 50 Z" fill="#5C98D1" />
              </svg>
            </div>
            <div className={styles.animatedStats}>
              <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                {component.disable_animations ? <span className={styles.number}>+{statistic}</span> : <CountUp className={styles.number} decimals={1} start={0.0} end={countUp ? +statistic : 0} duration={1.5} />}
              </VisibilitySensor>
              <span className={styles.percent}>%</span>
            </div>
            <p className={styles.animatedContent}>{content}</p>
          </div>
        </BackgroundImageWrapper>
        <Stats {...{ stats: statistics, onVisibilityChange, countUp }} disable_animations={component.disable_animations} />
      </div>
    </section>
  )
}
