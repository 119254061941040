import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import * as styles from './blogVideo.module.scss'

export default (props) => {
  const { component: { url } } = props
  const [playing, setPlaying] = useState(false)
  const handlePlay = () => setPlaying(!playing)

  return url
    ? (
      <section className={styles.section}>
        <ReactPlayer playing={playing} width='100%' height='100%' url={url} />
        <div className={styles.playContainer}>
          <div className={styles.button + `${playing ? ` ${styles.playing}` : ''}`} onClick={handlePlay} />
        </div>
      </section>
      )
    : null
}
