// extracted by mini-css-extract-plugin
export var section = "blogImageCarousel-module--section--3tV6v";
export var image = "blogImageCarousel-module--image--3R1FV";
export var fit = "blogImageCarousel-module--fit--2kulf";
export var navigate = "blogImageCarousel-module--navigate--1e3lO";
export var previous = "blogImageCarousel-module--previous--1rz3m";
export var next = "blogImageCarousel-module--next--34gir";
export var zoom = "blogImageCarousel-module--zoom--zFRJw";
export var info = "blogImageCarousel-module--info--1UYeV";
export var description = "blogImageCarousel-module--description--mdNqJ";
export var selectContainer = "blogImageCarousel-module--select-container--2tamO";
export var index = "blogImageCarousel-module--index--13J09";
export var left = "blogImageCarousel-module--left--3YBIC";
export var right = "blogImageCarousel-module--right--31DB5";
export var fullScreenContainer = "blogImageCarousel-module--full-screen-container--2JXVP";
export var lightbox = "blogImageCarousel-module--lightbox--1aEFG";
export var visible = "blogImageCarousel-module--visible--2dkQK";