// extracted by mini-css-extract-plugin
export var section = "process-module--section--1RR_M";
export var label = "process-module--label--B1uhz";
export var subheading = "process-module--subheading--1maHI";
export var infographics = "process-module--infographics--3C1-N";
export var mobileInfographics = "process-module--mobile-infographics--38r4U";
export var graphicThree = "process-module--graphic-three--3J9SA";
export var graphicOne = "process-module--graphic-one--360NM";
export var graphicFive = "process-module--graphic-five--1O1SP";
export var graphicTwo = "process-module--graphic-two--3WdSX";
export var graphicFour = "process-module--graphic-four--vXhPc";
export var iconOne = "process-module--icon-one--3L-OI";
export var bottomOne = "process-module--bottom-one--1bgim";
export var iconTwo = "process-module--icon-two--3L2r5";
export var bottomTwo = "process-module--bottom-two--20DYn";
export var iconThree = "process-module--icon-three--3KJK2";
export var rotating = "process-module--rotating--1s3O4";
export var bottomThree = "process-module--bottom-three--2YmCt";
export var iconFour = "process-module--icon-four--20nmk";
export var bottomFour = "process-module--bottom-four--K6tiU";
export var iconFive = "process-module--icon-five--2G-H5";
export var bottomFive = "process-module--bottom-five--2-O8r";
export var active = "process-module--active--7XOsb";
export var buttons = "process-module--buttons--2aTEz";
export var button = "process-module--button--3y0zH";