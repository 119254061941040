import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import CareerSlider from './careerCarousel'
import * as styles from './careerCarousel.module.scss'

export default ({ component: { label } }) => {
  const query = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      filter: {fields: {slug: {glob: "/careers/*/"}}, frontmatter: {title: {ne: "Failsafe"}}}
    ) {
      nodes {
        frontmatter {
          content
          title
        }
        fields {
          slug
        }
      }
    }
  }
  `)

  const { allMarkdownRemark: { nodes } } = query

  const items = nodes.map(({ frontmatter, fields: { slug } }) => ({ ...frontmatter, slug }))

  return (
    <div className={styles.section}>
      <h2 className={styles.heading}>{label}</h2>
      <CareerSlider
        {...{
          items,
          visibleCount: 2,
          styles,
          isMobile: false,
          className: styles.desktopItem,
          itemWidthPlusMargin: 32
        }}
      />
      <CareerSlider
        {...{
          items,
          visibleCount: 1,
          styles,
          className: styles.mobileItem,
          itemWidthPlusMargin: 86,
          isMobile: true
        }}
      />
    </div>
  )
}
