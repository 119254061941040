import React from 'react'
import parse from 'html-react-parser'

import * as styles from './address.module.scss'

export default ({ address }) => {
  const str = address.replace(/(?:\r\n|\r|\n)/g, '<br>')
  return (
    <div className={styles.address}>
      {str && parse(str)}
    </div>
  )
}
