import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import * as styles from './contact.module.scss'

export default () => {
  const { allSettingsJson: { nodes } } = useStaticQuery(graphql`{
    allSettingsJson {
      nodes {
        footerComponents {
          contactLinks {
            label
            path
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.contact}>
      {nodes[0].footerComponents.contactLinks.map((link, i) => (
        <Link key={i} to={link.path} className={styles.link}>{link.label}</Link>
      ))}
    </div>
  )
}
