import { Link } from 'gatsby'
import React from 'react'
import Image from '../../../../../utilities/image'
import Slider from '../../../../../utilities/slider'

type BlogSliderProps = {
  items: { title: string, heroImage: string, slug: string }[];
  visibleCount: number;
  itemWidthPlusMargin: number;
  isMobile: boolean;
  className: string;
}

export default (props: BlogSliderProps) => {
  const { items, className } = props
  return (
    <Slider {...props}>
      {items.map((item, i) => (
        <Link
          to={item.slug}
          key={i}
          className={className}
        >
          <Image src={item.heroImage} loading='eager' />
          <h4>{item.title}</h4>
        </Link>
      ))}
    </Slider>
  )
}
