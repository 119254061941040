// extracted by mini-css-extract-plugin
export var section = "blogHeader-module--section--SyUkh";
export var image = "blogHeader-module--image--2OEv7";
export var info = "blogHeader-module--info--2J59d";
export var backContainer = "blogHeader-module--back-container--KuWrc";
export var backArrow = "blogHeader-module--back-arrow--1gv0U";
export var backLabel = "blogHeader-module--back-label--MpL8v";
export var title = "blogHeader-module--title--2UAeh";
export var detailsContainer = "blogHeader-module--details-container--3OZ1K";
export var details = "blogHeader-module--details--24nC1";
export var detail = "blogHeader-module--detail--2DOzE";
export var contentDetails = "blogHeader-module--content-details--I_iNt";
export var hide = "blogHeader-module--hide--5mmFX";