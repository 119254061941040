import React, { useState } from 'react'
import Image from '../../../../../utilities/image'

import * as styles from './blogImageCarousel.module.scss'

export default ({ component: { imageCarousel } }) => {
  const [active, setActive] = useState(0)
  const [lightbox, setLightbox] = useState(false)

  const handleNextImage = () => setActive(active === imageCarousel.length - 1 ? 0 : active + 1)
  const handlePrevImage = () => setActive(active - 1 < 0 ? imageCarousel.length - 1 : active - 1)

  const handleZoom = () => setLightbox(!lightbox)
  console.group('Image carousel active image', imageCarousel && imageCarousel[active], imageCarousel)
  return (
    imageCarousel && imageCarousel.length && imageCarousel[active]
      ? (
        <div className={styles.section}>
          <div className={styles.image} style={{ backgroundImage: `url(${imageCarousel[active].image})` }}>
            <div className={styles.navigate}>
              <div className={styles.previous} onClick={handlePrevImage} />
              <div className={styles.zoom} onClick={handleZoom} />
              <div className={styles.next} onClick={handleNextImage} />
            </div>
          </div>
          <div className={styles.info}>
            <p className={styles.description}>{imageCarousel[active].description}</p>
            <div className={styles.selectContainer}>
              <button className={styles.left} onClick={handlePrevImage}><Image src='/arrow-left.svg' /></button>
              <p className={styles.index}>{active + 1}/{imageCarousel.length}</p>
              <button className={styles.right} onClick={handleNextImage}><Image src='/arrow-right.svg' /></button>
            </div>
          </div>
          <div className={styles.fullScreenContainer + `${lightbox ? ` ${styles.visible}` : ''}`}>
            <div className={styles.lightbox} style={{ backgroundImage: `url(${imageCarousel[active].image})` }} onClick={handleZoom} />
          </div>
        </div>
        )
      : null
  )
}
