import React from 'react'
import parse from 'html-react-parser'

import * as styles from './blogParagraphs.module.scss'

export default (props) => {
  const { component: { paragraphs } } = props
  return (
    <section className={styles.section}>
      {paragraphs && parse(paragraphs)}
    </section>
  )
}
