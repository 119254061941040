import React from 'react'

import * as styles from './alternatingList.module.scss'

export default ({ component: { label, orderedList } }) => {
  return (
    <section className={styles.section}>
      <h2 className={styles.label}>{label}</h2>
      <div className={styles.items}>
        {orderedList.map((item, i) => (
          <div key={i} className={styles.item}>
            <div className={styles.imageContainer}>
              <div className={styles.image} style={{ backgroundImage: `url(${item.image})` }} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.text}>
                <div className={styles.icon}>
                  <p className={styles.iconText}>{`0${i + 1}`}</p>
                </div>
                <h3 className={styles.itemLabel}>{item.label}</h3>
                <p className={styles.itemContent}>{item.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
