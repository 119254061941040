import React from 'react'
import IconTextSlider from './iconsTextSlider'
import * as styles from './iconsTextSlider.module.scss'

export default ({ component: { iconTextInfo } }) => {
  return (
    <>
      <IconTextSlider
        {...{
          items: iconTextInfo,
          visibleCount: 3,
          styles,
          className: styles.desktopItem,
          itemWidthPlusMargin: 28,
          isMobile: false
        }}
      />
      <IconTextSlider
        {...{
          items: iconTextInfo,
          visibleCount: 1,
          styles,
          className: styles.mobileItem,
          itemWidthPlusMargin: 86,
          isMobile: true
        }}
      />
    </>
  )
}
