// extracted by mini-css-extract-plugin
export var section = "teamMembers-module--section--3NAOt";
export var labelContainer = "teamMembers-module--label-container--VPiD5";
export var label = "teamMembers-module--label--2DqyJ";
export var content = "teamMembers-module--content--1jN1v";
export var team = "teamMembers-module--team--1EWJr";
export var member = "teamMembers-module--member--1n-SZ";
export var image = "teamMembers-module--image--XS32y";
export var info = "teamMembers-module--info--FwD8z";
export var name = "teamMembers-module--name--13y8f";
export var location = "teamMembers-module--location--1mjDu";