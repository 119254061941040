import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Masonry from 'react-masonry-component'

import * as styles from './blogGrid.module.scss'

export default () => {
  const query = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      filter: {fields: {slug: {glob: "/blog/*"}}}
      sort: {fields: frontmatter___timestamp, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          heroImage
        }
      }
    }
  }
  `)

  const { allMarkdownRemark: { nodes } } = query
  return (
    <section className={styles.section}>
      <Masonry className={styles.container}>
        {nodes.map((node, i) => (
          <Link key={i} className={styles.item} to={node.fields.slug}>
            <div className={styles.boxes}>
              <div className={styles.image} style={{ backgroundImage: `url(${node.frontmatter.heroImage})` }} />
              <p className={styles.title}>{node.frontmatter.title}</p>
            </div>
          </Link>
        ))}
      </Masonry>
    </section>
  )
}
