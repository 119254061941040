import React from 'react'
import { PageLink } from '../../../../../utilities'
import * as styles from './links.module.scss'

export default ({ pageLinks }) => {
  return (
    <section className={styles.section}>
      {pageLinks.map((pageLink, i) =>
        pageLink.linkType === 'Page'
          ? (
            <PageLink key={i} className={styles.link} page={pageLink.page}>
              {pageLink.label}
            </PageLink>
            )
          : pageLink.linkType === 'URL'
            ? (
              <a key={i} className={styles.link} href={pageLink.url} target='_blank' rel='noopener noreferrer'>
                {pageLink.label}
              </a>
              )
            : null
      )}
    </section>
  )
}
