import React from 'react'
import * as styles from './svgImage.module.scss'

export default ({ image, xPos = '0', yPos = '-1.5', mobileImageX = '-50', mobileImageY = '0' }) => {
  const y = +yPos * 10000
  const x = +xPos * 10000
  return (
    <div className={styles.background}>
      <svg className={styles.desktop} xmlns='http://www.w3.org/2000/svg' viewBox='-49 5 70870 29670'>
        <defs>
          <clipPath id='clip'>
            <path d='M -6549 5 L 250 23318 C 1144 26895 3090 29176 7098 29670 L 42334 29670 C 45479 29608 46651 27450 47576 25662 L 52817 13082 C 49364 20605 44369 18262 42272 13205 L 36537 9' fill='#000000' />
          </clipPath>
          <linearGradient id='gradient' gradientTransform='rotate(90)'>
            <stop offset='50%' stop-color='rgba(0,0,0,0)' />
            <stop offset='95%' stop-color='rgba(0,0,0,.69)' />
          </linearGradient>
        </defs>
        <path d='M 52817 13082 L 58200 9 41537 9 38600 6500 38600 20000 47576 25662 Z' fill='#5C98D1' />
        <image href={image} y={y} x={x} clipPath='url(#clip)' />
        <path d='M -6549 5 L 250 23318 C 1144 26895 3090 29176 7098 29670 L 42334 29670 C 45479 29608 46651 27450 47576 25662 L 52817 13082 C 49364 20605 44369 18262 42272 13205 L 36537 9' fill='url(#gradient)' />
      </svg>
      <svg className={styles.mobile} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 90.63'>
        <defs>
          <clipPath id='mobile-clip'>
            <path d='M 0 0 L 0 70 C 3 97 38 98 42 70 C 41 75 35 75 34 70 L 26 0 Z' fill='#000000' />
          </clipPath>
          <linearGradient id='mobile-gradient' gradientTransform='rotate(90)'>
            <stop offset='10%' stop-color='rgba(0,0,0,0)' />
            <stop offset='95%' stop-color='rgba(0,0,0,.69)' />
          </linearGradient>
        </defs>
        <path d='M 18 0 L 28 70 C 32 78 40 80 42 70 L 54 0 Z' fill='#5C98D1' />
        <image href={image} x={mobileImageX} y={mobileImageY} clipPath='url(#mobile-clip)' />
        <path d='M 0 0 L 0 70 C 3 97 38 98 42 70 C 41 75 35 75 34 70 L 26 0 Z' fill='url(#mobile-gradient)' />
      </svg>
    </div>
  )
}
