import React from 'react'
import BackgroundImageWrapper from '../backgroundImageWrapper'

import * as styles from './iconGrid.module.scss'

export default ({ component: { label, iconInfo, image } }) => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <BackgroundImageWrapper image={image}>
          <h2 className={styles.label}>{label}</h2>
          <div className={styles.iconGrid}>
            {iconInfo.map((item, i) => (
              <div key={i} className={styles.item}>
                <div className={styles.iconWrapper}>
                  <div
                    className={styles.icon}
                    style={{ backgroundImage: `url(${item.icon})` }}
                  />
                </div>
                <p className={styles.content}>{item.content}</p>
              </div>
            ))}
          </div>
        </BackgroundImageWrapper>
      </div>
    </section>
  )
}
