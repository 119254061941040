import React from 'react'
import Image from '../../../../../utilities/image'
import * as styles from './backgroundImageWrapper.module.scss'

export default ({ image, children }) => {
  return (
    <div className={styles.heading}>
      <Image className={styles.statBackground} src={image} />
      <div className={styles.headingGradient} />
      {children}
    </div>
  )
}
