import React from 'react'
import Fade from 'react-reveal/Fade'

import { Address, Contact, Logo, PageLinks, Phone, Social } from '../footer/components'

import settings from '../../../settings/settings.json'

import * as styles from './footer.module.scss'

export default () => {
  const { footerComponents: { pageLinks, socialLinks, contact, phoneNumber, address, logo } } = settings
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className={styles.footer}>
      <div className={styles.parent}>
        <div className={styles.container}>
          <div className={styles.columnOne}>
            <Fade left>
              <PageLinks pageLinks={pageLinks} />
            </Fade>
            <Fade left>
              <Social socialLinks={socialLinks} />
            </Fade>
          </div>
          <div className={styles.columnTwo}>
            <div className={styles.rowOne}>
              <Fade left>
                <div className={styles.contact}>
                  <p>Contact</p>
                  <Contact />
                </div>
              </Fade>
              <Fade left>
                <div className={styles.address}>
                  <p>Headquarters</p>
                  <Address address={address} />
                </div>
              </Fade>
            </div>
            <div className={styles.rowTwo}>
              <Fade left>
                <div className={styles.phone}>
                  <p>Call us</p>
                  <Phone phone={phoneNumber} />
                </div>
              </Fade>
              <Fade left>
                <Logo logo={logo} />
              </Fade>
            </div>
          </div>
        </div>
        <p className={styles.copyright}>{`© U.S. Veterinary REIT ${year}   |   `}<a href='https://station8branding.com' rel='noopener noreferrer' target='_blank' className={styles.branding}>Website by Station8</a></p>
      </div>
    </footer>
  )
}
