import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Logo from '../../../main/components/logo'

import * as styles from './logo.module.scss'

export default () => {
  const { allSettingsJson: { nodes } } = useStaticQuery(graphql`{
    allSettingsJson {
      nodes {
        headerComponents {
          logo
          template
        }
      }
    }
  }`)

  const [component] = useState(nodes[0].headerComponents.find(component => component.template === 'header-logo'))

  return (
    <Link to='/' className={styles.link}>
      <div className={styles.logo} style={{ backgroundImage: component ? `url(${component.logo})` : '' }} />
      <div className={styles.mobileLogo}>
        <Logo color='#5C98D1' />
      </div>
    </Link>
  )
}
