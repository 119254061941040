// extracted by mini-css-extract-plugin
export var section = "twoColumnInfo-module--section--3ECoe";
export var column = "twoColumnInfo-module--column--1-ShL";
export var label = "twoColumnInfo-module--label--2IT-x";
export var content = "twoColumnInfo-module--content--2boki";
export var icons = "twoColumnInfo-module--icons--2oIbw";
export var item = "twoColumnInfo-module--item--2msVD";
export var icon = "twoColumnInfo-module--icon--K2E8b";
export var iconText = "twoColumnInfo-module--icon-text--2gTP5";
export var chartInfo = "twoColumnInfo-module--chart-info--5i2XL";
export var chart = "twoColumnInfo-module--chart--21hHx";
export var number = "twoColumnInfo-module--number--1BWRF";
export var percent = "twoColumnInfo-module--percent--g5RjO";