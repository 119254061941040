import React from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'

import * as styles from './teamMembers.module.scss'

export default ({ component }) => {
  const { headingTeam: { label, content }, member } = component
  return (
    <section className={styles.section} id='team'>
      <div className={styles.labelContainer}>
        <h2 className={styles.label}>{label}</h2>
        <p className={styles.content}>{content}</p>
      </div>
      <div className={styles.team}>
        {member.map((member, i) => (
          <Link to={`/about/${slugify(member.name).toLowerCase()}`} key={i} className={styles.member}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${member.image})` }}
            />
            <div className={styles.info}>
              <p className={styles.name}>{member.name}</p>
              <p className={styles.location}>{member.titleLocation}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
