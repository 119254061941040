import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import parse from 'html-react-parser'

import * as styles from './devDescription.module.scss'

export default ({ component: { name, description, datasource, id }, templates }) => {
  // console.log('TEMPLATES', templates)

  const [template, setTemplate] = useState(undefined)

  useEffect(() => {
    const template = templates.find(({ template }) => template === name)
    // console.log('template yay!', template)
    setTemplate(template)
  }, [templates])

  // console.log('devDescription', name, description, datasource, id)
  const getTypeName = field => {
    const names = {
      boolean: 'True or False',
      field_group: 'Field Group',
      field_group_list: 'Repeatable Field Group',
      file: 'Image',
      image_gallery: 'Images',
      list: 'List',
      number: 'Number',
      select: 'Select',
      text: 'Text',
      textarea: 'Text Area'
    }
    return field.type === 'select' && field.config && field.config.options && field.config.options.length ? 'Choice of ' + field.config.options.join(', ') : field.type === 'textarea' && field.wysiwyg ? 'Rich Text' : names[field.type] || field.type
  }

  const Fields = ({ fields }) => {
    return (
      <ul className={styles.fields}>
        {fields.map((field, i) => {
          return (
            <li key={i}>
              <h3>{field.label}</h3>
              <h5>{getTypeName(field)} | {(field.config && field.config.required) || (field.config && field.config.min) || field.type === 'file' ? 'Required' : 'Optional'}{`${field.config && field.config.min ? ` | Min: ${field.config.min}` : ''}`}{`${field.config && field.config.max ? ` | Max: ${field.config.max}` : ''}`}</h5>
              {field.description ? <p>{parse(field.description)}</p> : <p>No description provided.</p>}
              {field.fields && field.fields.length
                ? <Fields fields={field.fields} />
                : null}
            </li>
          )
        })}
      </ul>
    )
  }

  return template ? (
    <section className={styles.section}>
      <h1 className={styles.label}>{template.label}</h1>
      <h2>Description</h2>
      <div className={styles.description}>
        {description ? parse(description) : <p>No description provided.</p>}
      </div>
      <h2>Datasource</h2>
      <div className={styles.datasource}>
        {datasource ? parse(datasource) : <p>No datasource description provided.</p>}
      </div>
      <h2>Fields</h2>
      {template.fields
        ? <Fields fields={template.fields} />
        : null}
      <p className={styles.arrow}>↓</p>
    </section>
  )
    : null
}
