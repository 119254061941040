// extracted by mini-css-extract-plugin
export var section = "animatedInfographic-module--section--198Xs";
export var container = "animatedInfographic-module--container--2hZGz";
export var labelContainer = "animatedInfographic-module--label-container--1xOkY";
export var label = "animatedInfographic-module--label--2Mk1-";
export var content = "animatedInfographic-module--content--1m2Yq";
export var animatedGraphic = "animatedInfographic-module--animated-graphic--9kq7b";
export var graphic = "animatedInfographic-module--graphic--23t-G";
export var active = "animatedInfographic-module--active--lQ6w-";
export var grow = "animatedInfographic-module--grow--3qpAh";
export var arrow = "animatedInfographic-module--arrow--2zdlj";
export var animatedStats = "animatedInfographic-module--animated-stats--3x2AE";
export var number = "animatedInfographic-module--number--1kcqy";
export var percent = "animatedInfographic-module--percent--38NAS";
export var animatedContent = "animatedInfographic-module--animated-content--3s1Z4";