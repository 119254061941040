// extracted by mini-css-extract-plugin
export var section = "articles-module--section--1SvXu";
export var articleContainer = "articles-module--article-container--1TtL7";
export var articleContainerMobile = "articles-module--article-container-mobile--36YLZ";
export var article = "articles-module--article--zaTU5";
export var articleMobile = "articles-module--article-mobile--3jJIB";
export var icon = "articles-module--icon--3Ir2S";
export var iconMobile = "articles-module--icon-mobile--30-pv";
export var label = "articles-module--label--1bCce";
export var labelMobile = "articles-module--label-mobile--3wyKr";
export var content = "articles-module--content--2HlvM";
export var contentMobile = "articles-module--content-mobile--9CkYE";
export var controls = "articles-module--controls--3FKDg";
export var controlsMobile = "articles-module--controls-mobile--3PWxU";
export var leftArrow = "articles-module--left-arrow--2fElZ";
export var leftArrowMobile = "articles-module--left-arrow-mobile--m7gjI";
export var rightArrow = "articles-module--right-arrow--DqgbR";
export var rightArrowMobile = "articles-module--right-arrow-mobile--36u6z";