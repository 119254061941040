import React, { useState } from 'react'

import * as styles from './process.module.scss'

export default ({ component }) => {
  const { label, bottomFive, bottomFour, bottomOne, subheading, bottomText, bottomTwo, iconFive, iconFour, iconOne, iconThree, iconTwo, topFour, topTwo, textThree } = component

  const [graphic, setGraphic] = useState(1)

  const visibilityStyle = index => `${graphic === index ? ` ${styles.active}` : ''}`

  return (
    <section className={styles.section}>
      <h2 className={styles.label}>{label}</h2>
      {subheading && <h4 className={styles.subheading}>{subheading}</h4>}
      <div className={styles.infographics}>
        <div className={styles.graphicOne}>
          <div className={styles.iconOne} style={{ backgroundImage: `url(${iconOne})` }} />
          <p className={styles.bottomOne}>{bottomOne}</p>
        </div>
        <div className={styles.graphicTwo}>
          <p className={styles.topTwo}>{topTwo}</p>
          <div className={styles.iconTwo} style={{ backgroundImage: `url(${iconTwo})` }} />
          <p className={styles.bottomTwo}>{bottomTwo}</p>
        </div>
        <div className={styles.graphicThree}>
          <div className={styles.iconThree} style={{ backgroundImage: `url(${iconThree})` }} />
          <p className={styles.bottomThree}>{textThree}</p>
        </div>
        <div className={styles.graphicFour}>
          <p className={styles.topFour}>{topFour}</p>
          <div className={styles.iconFour} style={{ backgroundImage: `url(${iconFour})` }} />
          <p className={styles.bottomFour}>{bottomFour}</p>
        </div>
        <div className={styles.graphicFive}>
          <div className={styles.iconFive} style={{ backgroundImage: `url(${iconFive})` }} />
          <p className={styles.bottomFive}>{bottomFive}</p>
        </div>
      </div>

      <div className={styles.mobileInfographics}>
        <div className={styles.graphicOne + visibilityStyle(1)}>
          <div className={styles.iconOne} style={{ backgroundImage: `url(${iconOne})` }} />
          <p className={styles.bottomOne}>{bottomOne}</p>
        </div>
        <div className={styles.graphicTwo + visibilityStyle(2)}>
          <p className={styles.topTwo}>{topTwo}</p>
          <div className={styles.iconTwo} style={{ backgroundImage: `url(${iconTwo})` }} />
          <p className={styles.bottomTwo}>{bottomTwo}</p>
        </div>
        <div className={styles.graphicThree + visibilityStyle(3)}>
          <div className={styles.iconThree} style={{ backgroundImage: `url(${iconThree})` }} />
          <p className={styles.bottomThree}>{textThree}</p>
        </div>
        <div className={styles.graphicFour + visibilityStyle(4)}>
          <p className={styles.topFour}>{topFour}</p>
          <div className={styles.iconFour} style={{ backgroundImage: `url(${iconFour})` }} />
          <p className={styles.bottomFour}>{bottomFour}</p>
        </div>
        <div className={styles.graphicFive + visibilityStyle(5)}>
          <div className={styles.iconFive} style={{ backgroundImage: `url(${iconFive})` }} />
          <p className={styles.bottomFive}>{bottomFive}</p>
        </div>
        {/* <div className={styles.buttons}>
          {[...new Array(5)].map((x, i) => (
            <button
              key={i}
              onClick={() => setGraphic(i + 1)}
              className={styles.button + visibilityStyle(i + 1)}
            />
          ))}
        </div> */}
      </div>
    </section>
  )
}
