import React from 'react'
import { Link } from 'gatsby'
import * as styles from './sharedTextButton.module.scss'
import Image from '../image'

type ButtonProps = {
  label: string
  buttonType: string
  link: string
  color?: string
  arrow?: string
  onClick?: () => any
}

export default ({ label, buttonType, link, color, arrow }: ButtonProps) => {
  return (
    <>
      {buttonType === 'URL' &&
        <a
          className={styles.button}
          href={link}
          style={{ color: color ?? '#5C98D1' }}
        >
          <span className={styles.label}>{label}</span><span><Image src={arrow ?? '/arrow.svg'} /></span>
        </a>}
      {buttonType === 'Page' &&
        <Link
          className={styles.button}
          to={link}
          style={{ color: color ?? '#5C98D1' }}
        >
          <span className={styles.label}>{label}</span><span><Image src={arrow ?? '/arrow.svg'} /></span>
        </Link>}
      {buttonType === 'Action' &&
        <button
          className={styles.button}
          style={{ color: color ?? '#5C98D1' }}
        >
          <span className={styles.label}>{label}</span><span><Image src={arrow ?? '/arrow.svg'} /></span>
        </button>}
    </>
  )
}
