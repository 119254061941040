// extracted by mini-css-extract-plugin
export var section = "oneColumnInfo-module--section--1w4ZW";
export var column = "oneColumnInfo-module--column--3FG-2";
export var label = "oneColumnInfo-module--label--2OJeC";
export var content = "oneColumnInfo-module--content--3E5Sg";
export var icons = "oneColumnInfo-module--icons--35uW5";
export var item = "oneColumnInfo-module--item--3UENL";
export var icon = "oneColumnInfo-module--icon--3Emqq";
export var iconText = "oneColumnInfo-module--icon-text--1V7E0";
export var chartInfo = "oneColumnInfo-module--chart-info--177Ou";
export var chart = "oneColumnInfo-module--chart--1r6Ia";
export var number = "oneColumnInfo-module--number--1pzLu";
export var percent = "oneColumnInfo-module--percent--2MVBy";