// extracted by mini-css-extract-plugin
export var section = "alternatingList-module--section--3pGz9";
export var label = "alternatingList-module--label--3tz3T";
export var items = "alternatingList-module--items--3JCRK";
export var item = "alternatingList-module--item--wIhVS";
export var imageContainer = "alternatingList-module--image-container--1vxjA";
export var image = "alternatingList-module--image--k5rIA";
export var textContainer = "alternatingList-module--text-container--ErWbQ";
export var text = "alternatingList-module--text--2H_Q5";
export var icon = "alternatingList-module--icon--2_Ypi";
export var iconText = "alternatingList-module--icon-text--1Zi0_";
export var itemLabel = "alternatingList-module--item-label--1Sm5l";
export var itemContent = "alternatingList-module--item-content--1A0t7";