import React from 'react'
import Logo from '../../../main/components/logo'

import * as styles from './logo.module.scss'

export default () => {
  return (
    <div className={styles.container}>
      <Logo color='#5C98D1' />
    </div>
  )
}
