import React, { useState } from 'react'
import { PageLink } from '../../../../../utilities'
import Logo from '../../../main/components/logo'

import * as styles from './links.module.scss'

export default ({ component: { headerLinks }, scrolled }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isActive, setIsActive] = useState(null)
  const handleMenuButton = () => {
    setMenuOpen(!menuOpen)
  }

  const location = typeof window !== 'undefined' ? window.location : undefined

  const colorBlackListPages = ['blog', 'contact', 'careers']

  const isBlacklistPage = colorBlackListPages.some(page => location?.pathname?.includes(page) ?? false)

  return (
    <section className={styles.section + `${menuOpen ? ` ${styles.active}` : ''}` + `${scrolled ? ` ${styles.scrolled}` : ''}`} onClick={handleMenuButton}>
      <div className={styles.container}>
        <div className={styles.mobileLogo}>
          <Logo color='#FFFFFF' />
        </div>
        {headerLinks.map((headerLink, i) => (
          <PageLink key={i} page={headerLink.page} className={styles.link} activeClassName={styles.active}>
            {headerLink.label}
          </PageLink>
        ))}
      </div>
    </section>
  )
}
