import React, { useState } from 'react'
import SharedTextButton from '../../../../../utilities/sharedTextButton/sharedTextButton'

import * as styles from './contactForm.module.scss'

export default ({ component: { heading1, text } }) => {
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
    emailVerify: '',
    message: '',
    when: '',
    how: 'email'
  })
  const [submitted, setSubmitted] = useState(false)

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!checkComplete() && form.email === form.emailVerify) {
      window.fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: encode({ 'form-name': 'contact', ...form })
      }).then(res => {
        setSubmitted(res.ok)
        return res.json()
      })
        .then(data => console.log(data))
    }
  }

  const checkComplete = () => {
    if (!form.name) {
      return 'Name required'
    }
    if (!form.phone) {
      return 'Phone number required'
    }
    if (!form.email) {
      return 'Email required'
    }
    if (!form.emailVerify) {
      return 'Confirm email required'
    }
    if (!form.message) {
      return 'Message required'
    }
    if (!form.when) {
      return 'Best time to contact required'
    }
    if (!form.how) {
      return 'Contact method required'
    }
    if (form.email !== form.emailVerify) {
      return 'Email must match confirmation'
    }
    return null
  }

  const handleChangeField = e => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
  }

  return (
    <section className={styles.section}>
      <div className={styles.text}>
        {text.split('\n').map((line, i) => (
          <p key={i} className={styles.line}>{line}</p>
        ))}
      </div>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>{heading1}</h2>
        {!submitted
          ? (
            <form
              name='contact'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
              className={styles.form}
              onSubmit={handleSubmit}
            >
              <input type='hidden' name='form-name' value='contact' />
              <p hidden>
                <label>
                  Don’t fill this out: <input name='bot-field' id='bot-field' onChange={handleChangeField} />
                </label>
              </p>
              <div className={styles.row}>
                <fieldset className={styles.field}>
                  <label htmlFor='name' className={styles.label}>Full name</label>
                  <input id='name' name='name' onChange={handleChangeField} className={styles.input} type='text' placeholder='Full name' required />
                </fieldset>
                <fieldset className={styles.field}>
                  <label htmlFor='phone' className={styles.label}>Phone number</label>
                  <input id='phone' name='phone' onChange={handleChangeField} className={styles.input} type='phone' placeholder='Phone number' required />
                </fieldset>
              </div>
              <div className={styles.row}>
                <fieldset className={styles.field}>
                  <label htmlFor='email' className={styles.label}>Email address</label>
                  <input id='email' name='email' onChange={handleChangeField} className={styles.input} type='email' placeholder='Email address' required />
                </fieldset>
                <fieldset className={styles.field}>
                  <label htmlFor='emailVerify' className={styles.label}>Confirm email address</label>
                  <input id='emailVerify' name='emailVerify' onChange={handleChangeField} className={styles.input} type='email' placeholder='Confirm email address' required />
                </fieldset>
              </div>
              <div className={styles.row}>
                <fieldset className={styles.field}>
                  <label htmlFor='message' className={styles.label}>Message</label>
                  <textarea id='message' name='message' rows={1} onChange={handleChangeField} className={styles.input} placeholder='Message' required />
                </fieldset>
              </div>
              <div className={styles.row + ` ${styles.alt}`}>
                <fieldset className={styles.field}>
                  <label htmlFor='when' className={styles.label}>Best time to contact</label>
                  <input id='when' name='when' className={styles.input} onChange={handleChangeField} type='text' placeholder='Best time to contact' required />
                </fieldset>
                <fieldset className={styles.field}>
                  <label htmlFor='how' className={styles.label}>Via</label>
                  <div className={styles.imageSelect} onClick={() => document.getElementById('how').click()}>
                    <div className={styles.email + `${form.phone === 'phone' ? ` ${styles.phone}` : ''}`} />
                    <select id='how' name='how' className={styles.input + ` ${styles.via}`} onChange={handleChangeField} required>
                      <option value='email'>Email</option>
                      <option value='phone'>Phone</option>
                    </select>
                  </div>
                </fieldset>
                <SharedTextButton {...{
                  label: "Let's Talk",
                  buttonType: 'Action',
                  link: '',
                  color: '#122D3C',
                  arrow: '/arrow-dark.svg'
                }}
                />
              </div>
              <p className={styles.error}>{checkComplete()}</p>
            </form>
            )
          : <p className={styles.submitted}>Thank you for your submission.</p>}
      </div>
    </section>
  )
}
