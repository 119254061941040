import React from 'react'

type props = {
  src: string,
  loading?: 'lazy' | 'eager',
  className?: any,
  imgClassName?: any,
  style?: any,
  imgStyle?: any,
  backgroundColor?: string,
  objectFit?: string,
  objectPosition?: string
}

export default ({ src, loading = 'lazy', className = null, imgClassName = null, style = null, imgStyle = null, backgroundColor = '', objectFit = 'cover', objectPosition = '50% 50%' }: props) => {
  return <img src={src} className={className} loading={loading} style={{ objectfit: objectFit, objectPosition, ...style }} />
}
