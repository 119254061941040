import React from 'react'
import * as styles from './stats.module.scss'
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'

export default ({ stats, onVisibilityChange, countUp, disable_animations }) => {
  return !disable_animations ? (
    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
      <div className={styles.stats}>
        {stats &&
          stats.map((stat, i) => {
            const decimal = stat.statistic?.includes('.') ? 1 : 0
            const start = decimal === 1 ? 0.0 : 0
            return (
              <div key={i} className={styles.stat}>
                <CountUp className={styles.statistic} start={start} decimals={decimal} end={countUp ? stat.statistic : 0} duration={1.5} />
                <span>
                  <strong>{stat.unit}</strong>
                </span>
                <p className={styles.info}>{stat.info}</p>
              </div>
            )
          })}
        <div className={styles.statsLine} />
      </div>
    </VisibilitySensor>
  ) : (
    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
      <div className={styles.stats}>
        {stats &&
          stats.map((stat, i) => {
            const decimal = stat.statistic?.includes('.') ? 1 : 0
            const start = decimal === 1 ? 0.0 : 0
            return (
              <div key={i} className={styles.stat}>
                <span className={styles.statistic}>{stat.statistic}</span>
                <span>
                  <strong>{stat.unit}</strong>
                </span>
                <p className={styles.info}>{stat.info}</p>
              </div>
            )
          })}
        <div className={styles.statsLine} />
      </div>
    </VisibilitySensor>
  )
}
