import React, { useState } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import Image from '../../../../../utilities/image'
import Stats from '../stats'
import BackgroundImageWrapper from '../backgroundImageWrapper'

import * as styles from './basicInfographic.module.scss'

export default ({ component }) => {
  const stats = component.statistic
  const [countUp, setCountUp] = useState(false)
  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUp(true)
    }
  }
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <BackgroundImageWrapper image={component.mainImage}>
          <h2 className={styles.label}>{component.label}</h2>
          <VisibilitySensor onChange={onVisibilityChange} delayedCall>
            <div className={styles.bigStatContainer}>
              <span className={styles.superScript}>$</span>
              <CountUp className={styles.bigStat} start={0} end={countUp ? component.mainStatistic : 0} duration={1.5} />
              <span className={styles.superScript}>B</span>
              <Image className={styles.up} src="/up.svg" />
            </div>
          </VisibilitySensor>
          <p className={styles.imageText}>{component.imageText}</p>
        </BackgroundImageWrapper>
        <Stats {...{ stats, onVisibilityChange, countUp }} disable_animations={component.disable_animations} />
      </div>
    </section>
  )
}
