import React from 'react'
import * as styles from './logo.module.scss'

type LogoProps = {
  image?: string
  color?: string
  xPos?: string
  yPos?: string
}

export default ({ image, xPos, yPos, color = 'rgba(92,152,209,.25)' }: LogoProps) => {
  const x = +xPos
  const y = +yPos

  return (
    <svg className={styles.svg} viewBox='-40 -46.3848 125 85.98' xmlns='http://www.w3.org/2000/svg'>
      {image &&
        <defs>
          <clipPath id='logo-clip'>
            <path d='m -3 3 c 10 -32 26 -26 30 -17 l 14 32 c 8 14 15 5 16 0 c -6 23 -25 25 -31 14 l -15 -35 c -2 -5 -10 -4 -13 3' fill='#000000' />
          </clipPath>
        </defs>}
      <path d='M 36.2 20 C 40.4 29 50.4 31 55.4 23 L 78.65 -41 C 69.65 -41 60.2 -41 55.2 -32' fill={color} />
      <path d='M -11.1 24 C 0.3 25 8.8 25 12.55 14 L 19.8 -5 C 8.8 -10 0.9 -7 -1.2 -2' fill={color} />
      <path d='m -3 3 c 10 -32 26 -26 30 -17 l 14 32 c 8 14 15 5 16 0 c -6 23 -25 25 -31 14 l -15 -35 c -2 -5 -10 -4 -13 3' fill='#414042' />
      {image && <image href={image} x={x} y={y} clipPath='url(#logo-clip)' />}
    </svg>
  )
}
