import React from 'react'

import * as styles from './social.module.scss'

export default ({ socialLinks }) => {
  return (
    <div className={styles.social}>
      {socialLinks.map((link, i) =>
        <a key={i} className={styles.link} href={link.url} target='_blank' rel='noopener noreferrer'>
          <div className={styles.image} style={{ backgroundImage: `url(${link.image})` }} />
        </a>
      )}
    </div>
  )
}
