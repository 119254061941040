import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import BlogSlider from './blogSlider'
import * as styles from './blogSlider.module.scss'

export default () => {
  const query = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      filter: {fields: {slug: {glob: "/blog/*"}}}
      sort: {fields: frontmatter___timestamp, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          heroImage
        }
      }
    }
  }
  `)

  const { allMarkdownRemark: { nodes } } = query

  const items = nodes.map(({ frontmatter, fields: { slug } }) => ({ ...frontmatter, slug }))

  return (
    <>
      <BlogSlider
        {...{
          items,
          visibleCount: 2,
          className: styles.desktopItem,
          itemWidthPlusMargin: 32,
          isMobile: false
        }}
      />
      <BlogSlider
        {...{
          items,
          visibleCount: 1,
          itemWidthPlusMargin: 86,
          isMobile: true,
          className: styles.mobileItem
        }}
      />
    </>
  )
}
