import React from 'react'
import SvgImage from '../svgImage/svgImage'
import SharedTextButton from '../../../../../utilities/sharedTextButton/sharedTextButton'
import * as styles from './hero.module.scss'

export default ({ component: { image, imageX, imageY, mobileImageX, mobileImageY, content, sharedTextButton: { label, buttonType, link } } }) => {
  return (
    <div className={styles.section}>
      <SvgImage {...{ image, xPos: imageX, yPos: imageY, mobileImageX, mobileImageY }} />
      <div className={styles.contentContainer}>
        <div className={styles.group}>
        <h1 className={styles.content}>{content}</h1>
        <SharedTextButton {...{ label, buttonType, link }} />
        </div>
      </div>
    </div>
  )
}
