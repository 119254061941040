import React from 'react'

import * as styles from './phone.module.scss'

export default ({ phone }) => {
  return (
    <div className={styles.phone}>
      <a href={`tel:+1${phone.replace(/\./g, '')}`}>{phone}</a>
    </div>
  )
}
