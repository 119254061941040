import React, { useState } from 'react'
import Image from '../image'
import * as styles from './slider.module.scss'

type SliderProps = {
  items: Record<string, any>[];
  visibleCount: number;
  itemWidthPlusMargin: number;
  isMobile: boolean;
  children: React.ReactElement[]
}

export default ({ items, visibleCount, itemWidthPlusMargin, isMobile, children }: SliderProps) => {
  const [clickable, setClickable] = useState<boolean>(true)
  const [slidePosition, setSlidePosition] = useState<number>(0)

  const maxLeft = items.length % visibleCount === 0
    ? Math.floor(items.length / visibleCount) - 1
    : Math.floor(items.length / visibleCount)

  const showSlideLeft = items.length > visibleCount && slidePosition < maxLeft
  const showSlideRight = items.length > visibleCount && slidePosition > 0

  const handleClick = (direction: 'left' | 'right') => {
    const canGoLeft = direction === 'left' && slidePosition < maxLeft
    const canGoRight = direction === 'right' && slidePosition > 0

    if (clickable && (canGoLeft || canGoRight)) {
      setClickable(false)
      setSlidePosition(direction === 'left' ? slidePosition + 1 : slidePosition - 1)
      setTimeout(() => setClickable(true), 260)
    }
  }

  const className = isMobile ? styles.mobileSlider : styles.desktopSlider

  return (
    <div className={styles.section}>
      <div className={className}>
        <div
          className={styles.slideContainer}
          style={{
            transform: `translateX(-${slidePosition * itemWidthPlusMargin * visibleCount}vw)`
          }}
        >
          {children}
        </div>
        <div className={styles.arrows}>
          <button
            style={{ visibility: `${showSlideRight ? 'visible' : 'hidden'}` }}
            onClick={() => handleClick('right')}
          >
            <Image src='/arrow-left.svg' />
          </button>
          <button
            style={{ visibility: `${showSlideLeft ? 'visible' : 'hidden'}` }}
            onClick={() => handleClick('left')}
          >
            <Image src='/arrow-right.svg' />
          </button>
        </div>
      </div>
    </div>
  )
}
