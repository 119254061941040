import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import { Footer, Header, Main } from './components'

import './reset.scss'
import './global.scss'
import * as styles from './layout.module.scss'

export default ({ children = null, title, description, metaTags = [], author, location, image = '', slug, components, templates = [] }) => {
  const { settingsJson: { siteInfo: { author: siteAuthor, description: siteDescription, metaTags: siteMetaTags, title: siteTitle, titleDivider } } } = useStaticQuery(graphql`
    {
      settingsJson {
        siteInfo {
          author
          description
          metaTags
          title
          titleDivider
        }
      }
    }
  `)
  return (
    <div className={styles.layout}>
      <Helmet>
        <html lang='en' />
        <title>{slug === '/' ? siteTitle : `${title || '404: Page Not Found'}` + titleDivider + siteTitle}</title>
        <meta name='description' content={description || siteDescription} />
        <meta name='keywords' content={metaTags ? metaTags.join(' ') : siteMetaTags.join(' ')} />
        <meta name='author' content={author || siteAuthor} />
        <meta property='og:title' content={slug === '/' ? siteTitle : `${title || '404: Page Not Found'}` + titleDivider + siteTitle} />
        <meta property='og:description' content={description || siteDescription} />
        <meta property='og:image' itemProp='image' content={image} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={location.href} />
        <meta property='twitter:title' content={slug === '/' ? siteTitle : `${title || '404: Page Not Found'}` + titleDivider + siteTitle} />
        <meta property='twitter:description' content={description || siteDescription} />
        <meta property='twitter:image' itemProp='image' content={image} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:card' content='summary_large_image' />
      </Helmet>
      <div className={styles.headerFill} />
      <Header />
      <Main components={components} templates={templates}>
        {children}
      </Main>
      <Footer />
    </div>
  )
}
