import React from 'react'
import { Link } from 'gatsby'
import { Slider } from '../../../../../utilities'

type CareerSliderProps = {
  items: { title: string, content: string, slug: string }[];
  visibleCount: number;
  itemWidthPlusMargin: number;
  isMobile: boolean;
  className: string;
}

export default (props: CareerSliderProps) => {
  const { items, className } = props

  return (
    <Slider {...props}>
      {items.map((item, i) => (
        <Link
          key={i}
          className={className}
          to={item.slug}
        >
          <div />
          <h3>{item.title}</h3>
          <p>{item.content}</p>
        </Link>
      ))}
    </Slider>
  )
}
