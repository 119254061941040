import React from 'react'
import ReactMarkDown from 'react-markdown'

import * as styles from './overview.module.scss'

export default ({ component: { label, content } }) => {
  return (
    <section className={styles.section}>
      {
        label
          ? (
            <>
              <h2 className={styles.label}>
                {label}
              </h2>

              <div className={styles.content}>
                <ReactMarkDown>{content}</ReactMarkDown>
              </div>
            </>
            )
          : (
            <div className={styles.noLabel}>
              <ReactMarkDown>{content}</ReactMarkDown>
            </div>
            )
      }
    </section>
  )
}
